<template>
  <div class="mymaterial">
    <div class="tabtop">
      <li
        v-for="(item, i) in tabData"
        :class="tabactive == i ? 'tabactive' : ''"
        :key="i"
        @click="tabactivecheck(i)"
      >
        {{ item.name }}
        <span v-show="tabactive == i"></span>
      </li>
    </div>
    <!-- 基本资料 -->
    <!-- <div v-show="tabactive == 0" class="content">
      <el-form
        :model="ruleForm1"
        :rules="rules1"
        ref="ruleForm1"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="头像" prop="name">
          <el-upload
            class="avatar-uploader"
            action="http://api.scwhy.cn/h5/user/saveAvatar"
            name="avatar"
            :headers="dataObj"
            :show-file-list="false"
            :on-success="handleAvatarSuccess1"
            :before-upload="beforeAvatarUpload"
          >
            <img
              v-if="ruleForm1.imageUrl"
              :src="ruleForm1.imageUrl"
              class="avatar"
              style="width: 60px; height: 60px; vertical-align: middle"
            />
            <img
              v-else
              src="../images/attestation.png"
              class="avatar"
              style="width: 60px; height: 60px; vertical-align: middle"
            />
            <span style="vertical-align: middle; color: #666; margin-left: 15px"
              >尺寸要求60*60像素，文件大小不得超过400KB</span
            >
          </el-upload>
        </el-form-item>
        <el-form-item label="账号" prop="accountnum">
          <el-input v-model="ruleForm1.accountnum" disabled=""></el-input>
        </el-form-item>
        <el-form-item label="昵称" prop="nickname">
          <el-input v-model="ruleForm1.nickname"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-radio-group v-model="ruleForm1.sex">
            <el-radio v-model="ruleForm1.sex" label="0">男</el-radio>
            <el-radio v-model="ruleForm1.sex" label="1">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="生日" prop="value1">
          <el-date-picker
            style="width: 100%"
            v-model="ruleForm1.value1"
            type="date"
            format="yyyy - MM - dd "
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item> -->
        <!-- <el-form-item label="区域" prop="selectedThree">
          <el-cascader
            :options="optionsThree"
            v-model="ruleForm1.selectedThree"
            placeholder="省市区三级联动"
            @change="handleChange"
            ref="three"
            style="width:100%"
          ></el-cascader>
        </el-form-item> -->
        <!-- <el-form-item label="职业" prop="zhiye">
          <el-input v-model="ruleForm1.zhiye"></el-input>
        </el-form-item> -->
        <!-- <el-form-item label="学历" prop="value">
          <el-select
            v-model="ruleForm1.value"
            placeholder="请选择"
            style="width:100%"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <!-- <el-form-item label="最高学历" prop="education">
          <el-select v-model="ruleForm1.value" placeholder="请选择" style="width: 100%">
            <el-option
              v-for="item in credentials"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手机" prop="phonenum">
          <el-input v-model="ruleForm1.phonenum" disabled=""></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="youxiang">
          <el-input v-model="ruleForm1.youxiang"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            style="color: #fff; background-color: #eb4f39; border: none"
            @click="submitForm1('ruleForm1')"
            >保存</el-button
          >
        </el-form-item>
      </el-form>
    </div> -->
    <!-- 实名认证 -->
    <!-- <div v-show="tabactive == 1" class="content"> -->
      <!-- <el-form
        :model="ruleForm2"
        :rules="rules2"
        ref="ruleForm2"
        label-width="100px"
        class="demo-ruleForm"
      > -->
        <!-- <el-form-item label="真实姓名" prop="name">
          <el-input v-model="ruleForm2.name"></el-input>
        </el-form-item>
        <el-form-item label="证件类型" prop="resource">
          <el-checkbox v-model="ruleForm2.checked">身份证</el-checkbox>
        </el-form-item>
        <el-form-item label="证件号码" prop="name">
          <el-input v-model="ruleForm2.zjnum"></el-input>
        </el-form-item> -->
        <!-- <el-form-item prop="dialogImageUrl" v-if="idCardnum == ''">
          <el-form-item
            prop="phone"
            label="身份证："
            style="margin-bottom: 30px; margin-left: -140px"
          >
            <el-input
              type="phone"
              v-model="ruleForm2.zjnum"
              autocomplete="off"
              placeholder="请输入身份证号"
            ></el-input>
          </el-form-item>
          <el-form-item prop="phone" label="姓名：" style="margin-left: -140px">
            <el-input
              type="phone"
              v-model="ruleForm2.name"
              autocomplete="off"
              placeholder="请输入真实姓名"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              style="
                color: #fff;
                background-color: #eb4f39;
                border: none;
                margin-top: 50px;
              "
              @click="submitForm2('ruleForm2')"
              >保存</el-button
            >
          </el-form-item> -->
          <!-- <el-upload
            action="http://api.scwhy.cn/h5/user/authentication"
            name="idCard"
            :headers="dataObj"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :limit="1"
            :on-success="handleAvatarSuccess"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <p>上传要求（限一张）示例：</p>
          <img src="../images/example2.jpg" alt="" /> -->
          <!-- <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="ruleForm2.dialogImageUrl" alt="" />
          </el-dialog> -->
        <!-- </el-form-item>
        <p
          v-if="idCardnum != '' && idCardnum != 500"
          style="font-size: 14px; color: #ccc; margin: 200px auto; text-align: center"
        >
          已实名认证:{{ idCardnum }}
        </p>
        <p
          v-if="idCardnum == 500"
          style="font-size: 14px; color: #ccc; margin: 200px auto; text-align: center"
        >
          审核中...
        </p> -->

        <!-- <el-form-item>
          <el-button type="primary" @click="submitForm2"
            >保存</el-button
          >
        </el-form-item> -->
      <!-- </el-form>
    </div> -->
    <!-- 基本信息 -->
    <div v-show="tabactive == 0" class="content">
     
      <el-form
        :model="ruleForm3"
        :rules="rules3"
        ref="ruleForm3"
        label-width="100px"
        class="demo-ruleForm"
      >
      <div class="nickname"><p>姓名：XXX</p><p> 身份证号：XXX</p></div>
        <el-form-item prop="phone" label="手机号：">
          <el-input
            type="phone"
            v-model="ruleForm3.phone"
            autocomplete="off"
            placeholder=""
          ></el-input>
        </el-form-item>
        <el-form-item prop="numcode" label="验证码：" style="position: relative">
          <el-input
            v-model="ruleForm3.numcode"
            autocomplete="off"
            placeholder="请输入手机验证码"
          ></el-input>
          <button class="getcode" type="button" @click="getnumcode" :disabled="disabled">
            {{ this.btnTitle }}
          </button>
        </el-form-item>
        <el-form-item label="昵称：" prop="nickname">
          <el-input></el-input>
         
        </el-form-item>  
        <div class="nickname1"><p>出生日期：XXX</p><p> 微信绑定状态：XXX</p><p> 读者证号：XXX</p></div>
        <!-- <el-form-item label="确认密码：" prop="checkPass">
          <el-input
            type="password"
            v-model="ruleForm3.checkPass"
            autocomplete="off"
          ></el-input>
        </el-form-item> -->
        <el-form-item style="width: 80%;
    margin: 0 auto;">
          <el-button
            style="color: #ffffff;	font-size: 16px; background-color: #eb4f39; border: none;width:100px;
	height: 35px;
	background-color: #ae703e;line-height:13px;
	border-radius: 5px;"
            @click="submitForm3('ruleForm3')"
            >保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { provinceAndCityData, regionData } from "element-china-area-data";
import API from "../api/api_person";
export default {
  name: "Nav",
  inject: ["reload"],
  data() {
    // 基本资料
    var valiaccountnum = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入账号"));
      } else {
        if (this.ruleForm1.accountnum !== "") {
          // this.$refs.ruleForm3.validateField("accountnum");
        }
        callback();
      }
    };
    //实名认证
    var valiacname = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入真实姓名"));
      } else {
        if (this.ruleForm2.name !== "") {
          // this.$refs.ruleForm3.validateField("accountnum");
        }
        callback();
      }
    };
    var valiazjnum = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入证件号码"));
      } else {
        if (this.ruleForm2.zjnum !== "") {
          // this.$refs.ruleForm3.validateField("accountnum");
        }
        callback();
      }
    };
    // 修改密码验证
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else if (value.length < 6) {
        callback(new Error("请输入至少6位数以上的密码"));
      } else {
        if (this.ruleForm3.checkPass !== "") {
          this.$refs.ruleForm3.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm3.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        },
      },
      tabactive: "0",
      tabData: [
      //   { name: "基本信息" },
      //  { name: "实名认证" }, 
       { name: "基本信息" }],
      currentPage: 1,
      credentials: [
        // {
        //   value: "0",
        //   label: "小学"
        // },
        // {
        //   value: "1",
        //   label: "初中"
        // },
        {
          value: "2",
          label: "高中",
        },
        {
          value: "3",
          label: "中专",
        },
        {
          value: "1",
          label: "大专",
        },
        {
          value: "0",
          label: "本科",
        },
        {
          value: "4",
          label: "本科及以上",
        },
        // {
        //   value: "7",
        //   label: "博士"
        // }
      ],
      optionsThree: regionData,
      optionsTwo: provinceAndCityData,
      selectedThree: ["120000", "120100", "120102"],
      selectedTwo: [],
      // 基本信息
      ruleForm1: {
        imageUrl: "",
        accountnum: "",
        nickname: "",
        sex: "",
        value1: "",
        selectedThree: "",
        zhiye: "",
        education: "",
        value: "",
        phonenum: "",
        youxiang: "",
      },
      rules1: {
        accountnum: [{ required: true, validator: valiaccountnum, trigger: "blur" }],
        nickname: [{ required: true, message: "请选择输入昵称", trigger: "blur" }],
        phonenum: [{ required: true, message: "请选择手机号", trigger: "blur" }],
      },
      //实名认证
      dialogVisible: false,
      ruleForm2: {
        name: "",
        checked: true,
        zjnum: "",
        dialogImageUrl: "",
      },
      rules2: {
        name: [{ required: true, validator: valiacname, trigger: "blur" }],
        zjnum: [{ required: true, validator: valiazjnum, trigger: "blur" }],
      },
      // 修改密码验证
      ruleForm3: {
        phone: "",
        numcode: "",
        pass: "",
        checkPass: "",
      },
      rules3: {
        phone: [{ required: true, message: "手机号必须为数字值" }],
        numcode: [{ required: true, message: "请输入验证码" }],
        pass: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
      },
      imageName: "",
      dataObj: {
        Authorization: localStorage.getItem("tokenlogin"),
      },
      xinxidetail: "",
      idCardnum: "",
      disabled: false,
      btnTitle: "发送验证码",
      changeimg: "666",
    };
  },
  props: {},
  methods: {
    tabactivecheck(index) {
      var that = this;
      that.tabactive = index;
      // that.reload();
    },
    //基本资料
    handleAvatarSuccess1(res, file) {
      this.ruleForm1.imageUrl = URL.createObjectURL(file.raw);
      this.$message({
        showClose: true,
        message: res.msg,
      });
    },
    beforeAvatarUpload(file11) {
      setTimeout(() => {
        API.getUser({}).then((res) => {
          this.changeimg = res.data;
          this.$emit("func", this.changeimg);
        });
      }, 100);
      const isJPG = file11.type === "image/jpeg";
      const isPNG = file11.type === "image/png";
      const isLt2M = file11.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG || (isPNG && isLt2M);
    },
    handleChange() {
  
    },
    //获取基本信息
    getUser() {
      API.getUser({}).then((res) => {
        this.xinxidetail = res.data;
        this.ruleForm1.imageUrl = res.data.avatar;
        this.ruleForm1.accountnum = res.data.loginName;
        this.ruleForm1.nickname = res.data.userName;
        this.ruleForm1.sex = res.data.sex;
        this.ruleForm1.value1 = res.data.birthday;
        this.ruleForm1.zhiye = res.data.occupation;
        this.ruleForm1.value = String(res.data.education);
        this.ruleForm1.phonenum = res.data.phonenumber;
        this.ruleForm1.youxiang = res.data.email;
      });
    },
    //基本信息上传
    // saveAvatar
    submitForm1(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // this.$router.go(0);
          API.updateUser({
            sex: this.ruleForm1.sex,
            userName: this.ruleForm1.nickname,
            occupation: this.ruleForm1.zhiye,
            education: this.ruleForm1.value,
            email: this.ruleForm1.youxiang,
            birthday: this.ruleForm1.value1,
          }).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: "恭喜你，这是一条成功消息",
                type: "success",
              });
              this.getUser();
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          return false;
        }
      });
    },
    //实名认证
    getAuthentication() {
      API.getAuthentication({}).then((res) => {
        if (res.code == 0) {
          this.idCardnum = res.idCard;
        } else if (res.code == 500) {
          this.idCardnum = 500;
        }
      });
    },
    handleRemove(file, fileList) {
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    submitForm2() {
      var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      if (!reg.test(this.ruleForm2.zjnum)) {
        this.$message({
          showClose: true,
          message: "请输入正确的身份证号",
          type: "error",
        });
      } else if (this.ruleForm2.name == "") {
        this.$message({
          showClose: true,
          message: "请输入您的姓名,姓名不能为空",
          type: "error",
        });
      } else if (!/^[\u4e00-\u9fa5]{2,6}$/.test(this.ruleForm2.name)) {
        this.$message({
          showClose: true,
          message: "请输入您的真实姓名,2-6位的汉字",
          type: "error",
        });
      } else {
        API.authentication({
          idNumber: this.ruleForm2.zjnum,
          name: this.ruleForm2.name,
        }).then((res) => {
          if (res.code == 0) {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "success",
            });
            setTimeout(() => {
              this.$router.go();
            }, 3000);
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
            });
          }
        });
      }
    },
    handleAvatarSuccess(res, file) {
      this.$message({
        showClose: true,
        message: res.msg,
      });
    },
    // 修改密码验证
    getnumcode() {
      // //倒计时
      if (!/^1[3456789]\d{9}$/.test(this.ruleForm3.phone)) {
        this.$message({
          showClose: true,
          message: "请输入正确的手机号",
          type: "error",
        });
      } else {
        this.validateBtn();
        this.getRegSmsCode();
      }
    },
    validateBtn() {
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.disabled = false;
          this.btnTitle = "获取验证码";
        } else {
          this.btnTitle = time + "秒后重试";
          this.disabled = true;
          time--;
        }
      }, 1000);
    },
    getRegSmsCode() {
      // RegSmsCode
      API.RegSmsCode({
        telephone: this.ruleForm3.phone,
      }).then((res) => {
        if (res.code == 0) {
          this.$message({
            showClose: true,
            message: "短信已发送，请注意查收",
            type: "success",
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    submitForm3(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!/^1[3456789]\d{9}$/.test(this.ruleForm3.phone)) {
            this.$message({
              showClose: true,
              message: "请输入正确的手机号",
              type: "error",
            });
          } else {
            API.backPWD({
              telephone: this.ruleForm3.phone,
              smsCode: this.ruleForm3.numcode,
              password: this.ruleForm3.pass,
              confirmPWD: this.ruleForm3.checkPass,
            }).then((res) => {
              if (res.code == 0) {
                this.$message({
                  message: "修改成功，请重新登录",
                  type: "success",
                });
                API.exit({}).then((res) => {
                  if (res.code == 0) {
                    setTimeout(() => {
                      this.$router.push({ path: "/accountlogin" });
                      localStorage.removeItem("token");
                    }, 2000);
                  } else {
                    this.$message.error(res.msg);
                  }
                });
              } else {
                this.$message.error(res.msg);
              }
            });
          }
        } else {
          return false;
        }
      });
    },
  },
  created() {
    this.getUser();
    this.getAuthentication();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.nickname{
  text-align: end;
    width: 17%;
    font-family: SourceHanSansCN-Regular;
    font-size: 16px !important;
    color: #666666;
    
}
.nickname1{
    text-align: end;
    margin-left: -4%;
    width: 21%;
    font-family: SourceHanSansCN-Regular;
    font-size: 16px;
    color: #666666 !important
    
}
::v-deep .item__label:before {
    content:none ;
    color: none;
    margin-right: 4px
    }
    ::v-deep .el-form-item__label{
      padding: 0 !important;
    }

::v-deep .el-form-item::before {
    display: table;
    content: "";
}
::v-deep .el-form-item__label{
	font-size: 16px;
	color: #666666;
  text-align: right;
    vertical-align: middle;
    float: left;
    line-height: 40px;
    padding: 0 12px 0 0;
    box-sizing: border-box;
}
::v-deep .el-input__inner{
 	font-size: 16px;
   color: #9ea8b8;
}
::v-deep .el-input{
  width: 315px;
	height: 40px;
}


/* **** */
.getcode {
  display: inline-block;
    width: 97px;
    height: 97%;
   	color: #ffffff;
    font-size: 16px;
    position: absolute;
    right: 53%;
    top: 0px;
    text-align: center;
    border: none;
    outline: none;
    background-color: #e26f12;
    border-radius: 0px 5px 5px 0px;

}
.getcode::before {
  content: "";
  display: inline-block;
  width: 1px;
  height: 21px;
  position: absolute;
  top: 10px;
  left: 0px;
}
/* **** */
.mymaterial {
  display: inline-block;
  width: 970px;
  min-height: 500px;
  background-color: #ffff;
}
.mymaterial .tabtop {
  width: 100%;
  height: 40px;
  /* border-bottom: 1px dashed #ccc; */
  border-bottom: 1px solid #ccc;
  display: flex;
}
.mymaterial .tabtop li {
  list-style: none;
  height: 100%;
  line-height: 40px;
  color: #666;
  font-size: 14px;
  width: 100px;
  text-align: center;
}
/* .mymaterial .tabtop .tabactive {
  color: #eb4f39;
} */
/* .mymaterial .tabtop li span {
  display: block;
  width: 30px;
  height: 2px;
  background-color: #eb4f39;
  margin-left: 35px;
  margin-top: -8px;
} */
.mymaterial .content {
  width: 80%;
  height: auto;
  margin: 40px auto;
  padding-bottom: 160px;
}
 ::v-deep .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
    content: '*';
    color: #ffffff;
    margin-right: 4px;
}
</style>
