<template>
  <div class="personalcenter">
    <Header></Header>
    <Nav></Nav>
    <!-- 顶部 -->
    <div class="s_top">
      <div class="content">
        当前位置：个人中心
      </div>
    </div>
    <!-- 主体部分 -->
    <div class="banner">
      <div class="navleft">
        <div class="top">
          <img v-if="avatar" :src="avatar" alt="" />
          <img v-else src="../images/0000.png" alt="" />
            <p class="xx" >HI～XXXX</p>
          <p class="hh">已实名</p>
           <p class="zero">0</p>
            <p class="rant">已借图书</p>
          <!-- <p v-if="userName != ''">{{ userName }}</p>
          <p v-if="userName == ''">{{ loginName }}</p> -->
        </div>
        <div class="cont">
          <li
            v-for="(i, j) in leftdata"
            :key="j"
            :class="nav_li == j ? 'isactive' : ''"
            @click="change(j)"
          >
            <span  v-show="nav_li == j"><img src="../images/zzsr33.png" /></span>{{ i.name }}
          </li>
        </div>
      </div>
      <div class="collection">
        <!-- <div v-if="nav_li == 0">
          <Collection></Collection>
        </div>
        <div v-if="nav_li == 1">
          <Notices></Notices>
        </div> -->
        <div v-if="nav_li == 0">
          <Material @func="getMsgFormSon"></Material>
        </div>
        <div v-if="nav_li == 1">
          <Order></Order>
        </div>
        <!-- <div v-if="nav_li == 4">
          <Volunteer></Volunteer>
        </div>
        <div v-if="nav_li == 5">
          <Activity></Activity>
        </div>
        <div v-if="nav_li == 6">
          <Yuyue></Yuyue>
        </div>
        <div v-if="nav_li == 7">
          <Mygxorder></Mygxorder>
        </div> -->
      </div>
    </div>
    <el-backtop :visibility-height="100"></el-backtop>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "../components/header.vue";
import Footer from "../components/footer.vue";
// import Collection from "../components/mycollection.vue";
// import Notices from "../components/mynotices.vue";
import Material from "../components/mymaterial.vue";
import Order from "../components/myorder.vue";
// import Volunteer from "../components/myvolunteer.vue";
// import Mygxorder from "../components/mygxorder.vue";
// import Activity from "../components/myactivity.vue";
// import Yuyue from "../components/yuyue.vue";
import API from "../api/api_person";
import Nav from "../components/nav.vue";
export default {
  name: "personalcenter",
  data() {
    return {
      nav_li: 0,
      leftdata: [
        // { name: "我的收藏" },
        // { name: "我的消息" },
        { name: "基本信息" }, { name: "已借图书" },
        // { name: "我的点单" },
        // { name: "志愿者" },
        // { name: "我的活动" },
        // { name: "我的预约" },
        // { name: "个性点单" }
      ],
      avatar: "",
      userName: "",
      loginName: "",
      msgFormSon: ""
    };
  },
  components: {
    Header,
    Footer,
    // Navleft
    // Collection,
    // Notices,
    Material,
    Order,
    // Volunteer,
    // Activity,
    // Yuyue,
    Nav,
    // Mygxorder
  },
  watch: {
    $route(to, from) {
      // 路由发生变化页面刷新
      // this.$router.go(0);
    }
  },
  methods: {
    change(index) {
      this.nav_li = index;
    },
    getUser() {
      API.getUser({}).then(res => {
        this.avatar = res.data.avatar;
        this.userName = res.data.userName;
        this.loginName = res.data.loginName;
      });
    },
    getMsgFormSon(data) {
      if (data) {
        this.avatar = data.avatar;
      }
    }
  },
  created() {
    this.getUser();
    this.getMsgFormSon();
  }
};
</script>
<style scope>
.personalcenter {
  width: 100%;
  height: 100%;
  background-color: #f7f6f2;
}
.xx{ 
	font-family: SourceHanSansCN-Medium;
	font-size: 16px !important;
	line-height: 17px;
	color: #666666 !important;
}
.zero{
	font-family: SourceHanSansCN-Medium;
	font-size: 14px !important;
	color: #ff0000 !important;
}
.rant{

	font-family: SourceHanSansCN-Medium;
	font-size: 12px !important;
	color: #666666 !important;
}
/* 内容 */
.personalcenter .s_top {
  width: 100%;
  height: 35px;
  background-color: #fff;
}
.personalcenter .s_top .content {
  width: 1200px;
  height: 100%;
  line-height: 35px;
  color: #666;
  margin: 0 auto;
  font-size: 12px;
}
.hh{
	font-family: SourceHanSansCN-Medium;
    width: 49px;
	height: 17px;
	background-color: #cc1010;
	border-radius: 9px 8px 8px 8px;
	letter-spacing: 0px;
	color: #fdfdfd;
    text-align: center;
}
/* 个人中心 */
.personalcenter .banner {
  width: 1200px;
  height: auto;
  min-height: 600px;
  margin: 20px auto 50px;
}
.personalcenter .banner .navleft {
  display: inline-block;
  min-height: 920px;
  background-color: #ffffff;
  vertical-align: top;
  border: solid 1px #e9e9e9;
  border-bottom: none;
}
.personalcenter .banner .navleft .top {
 	width: 224px;
    height: 264px;
    background-color: #e9e9e9;
    flex-direction: column;
    /* align-items: center; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.personalcenter .banner .navleft .top img {
 width: 111px;
	height: 111px;
  border-radius: 50%;
  margin-top: 25px;
}
.personalcenter .banner .navleft .top p {
  margin: 5px;
  color: #fff;
  font-size: 12px;
}
.personalcenter .banner .navleft .cont {
  width: 100%;
  /* margin-top: 20px; */
}
.personalcenter .banner .navleft .cont li {
  list-style: none;
  text-align: center;
  width: 223px;
  height: 54px;
  border-bottom:  solid 1px #e9e9e9 ;
  font-size: 16px;
  height: 45px;
  line-height: 45px;
  position: relative;
  font-weight: 500;
	color: #8a8a8a;
}
.personalcenter .banner .navleft .cont .isactive {
	font-family: MicrosoftYaHei;
	font-size: 16px  !important;
	color: #ae703e !important;
}
.personalcenter .banner .navleft .cont li span {
    display: inline-block;
    /* float: right; */
    position: absolute;
    width: 3px;
    height: 20px;
    top: 4.5px;
    /* background-color: #eb4f39; */
    left: 198px
}
.personalcenter .banner .collection {
  display: inline-block;
  width: 970px;
  min-height: 500px;
  height: auto;
  vertical-align: top;
  background-color: #ffffff;
 
}
.serchdiv{
  display: none !important;
}

 ::v-deep .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
    content: '*';
    color: #ffffff !important;
    margin-right: 4px;}
</style>