<template>
  <div class="myorder">
    <div class="top">
      <span>我的点单列表</span>
      <!-- <p class="search">
        <input type="text" placeholder="姓名电话" />
        <span class="el-icon-search"></span>
      </p> -->
    </div>
    <div class="cont">
      <el-table
        :data="tableData"
        style="width: 100%"
        @current-change="handleCurrentChange"
      >
        <el-table-column type="index" label="序号" align="center">
        </el-table-column>
        <el-table-column
          prop="params.whyCulture.title"
          label="活动名称"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="createTime" label="申报时间" align="center">
        </el-table-column>
        <!-- <el-table-column
          width="220"
          prop="address"
          label="申报状态"
          align="center"
        >
        </el-table-column> -->
      </el-table>
    </div>
  </div>
</template>
<script>
import API from "../api/api_person";
export default {
  name: "myorder",
  data() {
    return {
      tableData: []
    };
  },
  components: {},
  watch: {
    $route(to, from) {
      // 路由发生变化页面刷新
      // this.$router.go(0);
    }
  },
  methods: {
    // getCulture
    getCulture() {
      API.getCulture({}).then(res => {
        this.tableData = res.data.rows;
      });
    },
    handleCurrentChange(val) {
      this.$router.push({
        path: "/orderdetail",
        query: { id: val.cultureId }
      });
    }
  },
  created() {
    this.getCulture();
  }
};
</script>
<style>
.myorder {
  display: inline-block;
  width: 970px;
  min-height: 500px;
  background-color: #ffff;
}
.myorder .top {
  width: calc(100% - 40px);
  height: 55px;
  line-height: 55px;
  color: #666;
  border-bottom: 1px dashed #ccc;
  font-size: 14px;
  padding: 0px 20px;
}
.myorder .top span:nth-child(2n) {
  display: inline-block;
  width: 100px;
  height: 100%;
  float: right;
  text-align: right;
}
.myorder .cont {
  width: 900px;
  min-height: 500px;
  margin: 0px auto;
}
.myorder .top .search {
  display: inline-block;
  width: auto;
  height: 100%;
  float: right;
  margin: 0px;
  position: relative;
}
.myorder .top .search input {
  width: 240px;
  margin: 0px;
  height: 30px;
  padding-left: 15px;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 20px;
}
.myorder .top .search span {
  position: absolute;
  top: 12px;
  right: 0px;
  display: inline-block;
  width: 40px;
  text-align: center;
  height: 32px;
  line-height: 35px;
  font-size: 16px;
  padding-right: 10px;
}
</style>